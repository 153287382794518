<template>
  <v-container fluid :class="hasCallCenter ? 'call-center-margin' : ''">
    <v-layout column sheet>
      <h1 class="page-header">Reservations</h1>
      <DataTable ref="reservationsTable" v-bind.sync="tableProps">
        <template #multiSelectActions>
          <ReservationListMultiselectActions
            v-if="multiselectActive"
            :selected-rows="tableProps.selectedRows"
            @unselect-row="unselectRow"
          />
        </template>
      </DataTable>
    </v-layout>
  </v-container>
</template>

<script>
import { DateTime, Duration } from 'luxon'
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/DataTable.vue'
import ReservationTableDetail from '@/components/ReservationTableDetail.vue'
import ReservationTableActions from '@/components/ReservationTableActions.vue'
import brokerActionsWrapper from '@/components/BrokerAndAdminActionsWrapper.ts'
import ReservationActionableColumn from '@/components/ReservationActionableColumn.vue'
import TrackingColumn from '@/components/TrackingColumn.vue'
import QuotesActionableColumn from '@/components/QuotesActionableColumn.vue'
import PickupRadiusFilter from '@/components/PickupRadiusFilter.vue'
import { currencyFilter } from '@/utils/currency'
import { metersToMilesString } from '@/utils/distance'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'
import MultiMarketFilterVue from '@/components/MultiMarketFilter.vue'
import ReservationListMultiselectActions from '@/components/ReservationListMultiselectActions.vue'
import OperatorTierTypeLabelsFilter from '@/components/OperatorTierTypeLabelsFilter.vue'
import { EventBus } from '@/utils/event-bus'
import {
  calculatedValues,
  datePredefined,
  noFutureDatesPredefined,
  numericRangePredefined,
  textLike,
  userPredefined,
  customerPredefined,
  assignmentPercentagePredefined,
} from '@/utils/predefined'
import { v4 } from 'uuid'
import { diffInDaysFromNow } from '@/utils/time'
import { getTripAssignmentsForReservation } from '@/services/reservations'
import { SplitFeatureFlag, ReferralStatus, ReservationStatusKey } from '@/utils/enum'
import { capitalize } from '@/utils/string'

const reservationStatusMap = [
  { text: 'Upcoming', value: ReservationStatusKey.Upcoming },
  { text: 'Started', value: ReservationStatusKey.Started },
  { text: 'Finished', value: ReservationStatusKey.Finished },
  { text: 'Cancelled', value: ReservationStatusKey.Cancelled },
  { text: 'Hold', value: ReservationStatusKey.OnHold },
]
const referralStatusMap = [
  { text: 'Not Offered', value: ReferralStatus.NotOffered },
  { text: 'Offered', value: ReferralStatus.FullyOffered },
  { text: 'Accepted', value: ReferralStatus.FullyAccepted },
  { text: 'Confirmed', value: ReferralStatus.FullyConfirmed },
  { text: 'Offered', value: ReferralStatus.Offered },
  { text: 'Accepted', value: ReferralStatus.Accepted },
  { text: 'Rejected', value: ReferralStatus.Rejected },
  { text: 'Confirmed', value: ReferralStatus.Confirmed },
]
const paymentStatusMap = [
  { text: 'Unpaid', value: 'not_paid' },
  { text: 'Partially Paid', value: 'partially_paid' },
  { text: 'Fully Paid', value: 'fully_paid' },
]

const trackedStatusMap = [
  { text: 'Fully Tracked-On-Time', value: 'tracked' },
  { text: 'Partially Tracked-On-Time', value: 'partially_tracked' },
  { text: 'Not Tracked-On-Time', value: 'not_tracked' },
]

const categories = [
  {
    _t_id: 'cfb42fbe',
    text: 'Reservation Status',
    prop: 'reservationStatus',
    values: deepClone(reservationStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: 'c2883884',
    text: 'Referral Status',
    prop: 'referralStatus',
    values: deepClone(referralStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: 'cc4277cd',
    text: 'Payment Status',
    prop: 'paymentStatus',
    values: deepClone(paymentStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: '0196469e',
    text: 'Tracked Status',
    prop: 'trackedStatus',
    values: deepClone(trackedStatusMap),
    type: 'text',
    method: 'or',
  },
]

export default {
  components: {
    DataTable,
    ReservationListMultiselectActions,
  },
  metaInfo() {
    return {
      title: 'Reservations',
    }
  },
  data() {
    return {
      isMapSidebarActive: false,
      mapReservationId: null,
      latestRequestId: null,
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      itemsPerPage: 10,
      page: 1,
      isAdmin: false,
      teamClassificationsMap: [],
      isTiersEnabled: false,
      tableProps: {
        enableExport: true,
        exportAction: 'reservations/reservationsTableExport',
        exportName: 'reservations.csv',
        enableColumnConfig: true,
        enableSavedViews: true,
        enableStatusFilterToggle: true,
        canViewInactiveReservations: false,
        total: 0,
        tableId: 'reservation_table_view',
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        detail: ReservationTableDetail,
        detailKeyId: 'reservationId',
        isDetailed: true,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        categories,
        isAdmin: this.isAdmin,
        rowClass: this.rowClass.bind(this),
        calculatedValues,
        columns: [],
        id: 'reservations-list',
        enableSelectableRows: true,
        enableSelectAll: true,
        selectedRows: [],
      },
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      isAccountExecutiveAndOpportunityIdEnabled:
        'featureToggles/isAccountExecutiveAndOpportunityIdEnabled',
      tiersTVMap: 'tiers/getTiersTVMap',
      predefinedTiersTVMap: 'tiers/getPredefinedTiersTVMap',
    }),
    canViewOpsTotal() {
      return this.$store.getters['auth/canViewOpsTotal']
    },
    isAccountsReceivable() {
      return this.$store.getters['auth/isAccountsReceivable']
    },
    multiselectActive() {
      return this.$refs.reservationsTable.showMultiSelectActions
    },
    canViewGrossProfit() {
      const roles = this.currentUserProfile?.roles || []
      return roles.find((r) => r.roleName === 'is_report_admin')
    },
  },
  async mounted() {
    const user = this.currentUser
    this.tableProps.isAdmin = user?.group?.key === 'admin'
    this.tableProps.enableSelectableRows = !this.isRA
    const referralStatusCategoryId = 'c2883884'
    this.tableProps.canViewInactiveReservations = this.canViewInactiveReservations()
    this.tableProps.enableExport = this.canExport()

    this.tableProps.categories = this.tableProps.categories.map((category) => {
      if (category._t_id === referralStatusCategoryId) {
        category.values = category.values.filter((categoryValue) =>
          [
            ReferralStatus.NotOffered,
            ReferralStatus.FullyOffered,
            ReferralStatus.Confirmed,
            ReferralStatus.FullyConfirmed,
          ].includes(categoryValue.value)
        )
      }
      return category
    })

    this.isTiersEnabled = await this.isFeatureEnabled(SplitFeatureFlag.ServiceTier)
    if (this.isTiersEnabled) {
      await this.fetchAllTiers()
      this.initializeTiersTVSupport()
    }

    await this.getColumns()
    if (this.$route.query.reservationId) {
      const reservationIdFilter = {
        column: this.tableProps.columns.find(
          (column) => column._t_id === 'd7df53d5'
        ),
        value: this.$route.query.reservationId,
      }
      this.tableProps.initialFilters = [reservationIdFilter]
    }

    if (this.$route.path.includes('/map/')) {
      const reservation = await this.$store.dispatch(
        'reservations/fetchReservationById',
        {
          reservationId: this.$route.params.id,
          companyId: user?.companyId,
          viewRejectedReferrals: false,
          force: true,
        }
      )

      if (!reservation) {
        return
      }

      let referredProviders = reservation?.referredTo?.filter(
        (ref) => ref.referralStatus === 'accepted'
      )
      referredProviders = referredProviders?.map((provider) => {
        let computedName = provider.companyName
        if (
          referredProviders.filter(
            (p) => p.companyId === provider.companyId
          ).length > 1
        ) {
          computedName += ` - ${provider.managedId}`
        }
        return { computedName, ...provider }
      })

      const currentProvider = reservation?.referredTo?.[0]
      let tripVehicleGroups = []
      if (currentProvider?.tripVehicleGroups) {
        tripVehicleGroups = currentProvider.tripVehicleGroups
      } else {
        tripVehicleGroups = reservation.tripVehicleGroups
      }

      const trackingStatusResult = await this.$store.dispatch(
        'reservations/getReservationTrackingStatuses',
        [this.$route.params.id]
      )
      const trackingReservationStatus =
        trackingStatusResult?.data?.trackingStatuses[0]?.reservationStatus
      const trackingAllocation =
        trackingStatusResult?.data?.trackingStatuses[0]?.trackingAllocation

      const itineraryItems = reservation.stops?.map((stop, index) => {
        const {
          stopId,
          address,
          notes,
          stopNotes,
          risks,
          flightInformation,
          tripVehicleGroupId,
        } = stop
        const newStop = {
          stopNumber: index + 1,
          stopId,
          address,
          timeZone: address.timeZone,
          notes,
          stopNotes,
          risks,
          flightInformation,
          tripVehicleGroupId,
        }
        if (stop.dropoffDatetime && stop.pickupDatetime) {
          newStop.type = 'Drop-off\nPickup'
        } else if (stop.dropoffDatetime) {
          newStop.type = 'Drop-off'
        } else if (stop.pickupDatetime) {
          newStop.type = 'Pickup'
        } else {
          newStop.type = '--'
        }
        newStop.pickupDatetime = stop.pickupDatetime
        newStop.dropoffDatetime = stop.dropoffDatetime
        return newStop
      })

      const vehicleAssignmentsData = await getTripAssignmentsForReservation(
        { reservationIds: [reservation.reservationId] }
      )
      const tripAssignments = vehicleAssignmentsData.data.vehicleAssignments

      const closeHandler = () => {
        const pattern = /\map\/\d+/
        const path = this.$route.path.replace(pattern, '')
        history.replaceState({}, null, `/ci${path}`)
      }

      const component = () =>
        import('@/components/ReservationMapSidebar.vue')
      this.$store.dispatch('app/openDialog', {
        data: {
          active: true,
          reservationId: reservation.reservationId,
          managedId: reservation.managedId,
          reservationStatus: reservation.reservationStatus,
          journeys: reservation.journeys,
          startDate: reservation.startDate,
          tripVehicleGroups: tripVehicleGroups,
          stops: reservation.stops,
          referredProviders,
          fullTripVehicleGroups: reservation.tripVehicleGroups,
          trackingAllocation,
          trackingReservationStatus,
          tripAssignments,
          itineraryItems,
          closeHandler,
          linkToReservationDetail: true
        },
        component,
      })
      const mapReservationIdFilter = {
        column: this.tableProps.columns.find(
          (column) => column._t_id === 'd7df53d5'
        ),
        value: reservation.managedId,
      }
      this.tableProps.initialFilters = [mapReservationIdFilter]
    }

    EventBus.$on('global-table-view-refresh', () => {
      this.refresh()
    })
    EventBus.$on('successful-assignment', (event) => {
      this.refresh()
      setTimeout(() => {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: `Assignments for (${event.length}) reservations updated.`,
        })
      }, 1)
    })
    EventBus.$on('successful-clear-assignments', (event) => {
      this.refresh()
      setTimeout(() => {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: `Assignments for (${event.length}) reservations have been cleared.`,
        })
      }, 1)
    })
    EventBus.$on('successful-add-driver', (event) => {
      setTimeout(() => {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: `A new driver ${event} has been created.`,
        })
      }, 1)
    })
    EventBus.$on('update-assignment-percentages', (updatedAssignmentPercentages) => {
      const changeRes = this.tableProps.list.find((reservation) => reservation.reservationId === updatedAssignmentPercentages.reservationId)
      this.$set(changeRes, 'assignedDriverPercentage', updatedAssignmentPercentages.assignedDriverPercentage)
      this.$set(changeRes, 'assignedVehiclePercentage', updatedAssignmentPercentages.assignedVehiclePercentage)
    })
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
      fetchAllTiers: 'tiers/fetchAllTiers',
      initializeTiersTVSupport: 'tiers/initializeTiersTVSupport',
    }),
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    canViewInactiveReservations() {
      const roles = this.currentUserProfile?.roles || []
      const canViewInactiveReservations = roles.find(
        (r) => r.roleName === 'can_view_inactive_reservations_and_quotes'
      )
      return !!canViewInactiveReservations
    },
    async getColumns() {
      const columnsOut = []

      // Broker OR Admin Company
      const brokerWrappedActions = await brokerActionsWrapper(
        ReservationTableActions,
        this.currentUser
      )
      if (
        !this.isRA ||
        (this.isRA && (this.isL1Manager || this.isSeniorISR || this.isMmeSales))
      ) {
        columnsOut.push({
          _t_id: '089d3f8b',
          prop: '/',
          text: 'Actions',
          component: brokerWrappedActions,
          sort: false,
          filter: false,
          detail: false,
          type: 'actions',
          shrinkWidth: true,
        })
      }
      columnsOut.push(
        {
          _t_id: 'd7df53d5',
          prop: 'managedId',
          component: ReservationActionableColumn,
          displayType: 'clickable-link',
          action: 'NOOP',
          text: 'ID',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'startswith',
          // If the value matches a Reservation Referral (123456-1), then do an exact match
          computedFilterType: (value) =>
            /^\d+-\d+$/.test(value) ? 'eq' : 'startswith',
          sortProp: 'managedId',
          filterProp: 'managedId',
        },
        {
          _t_id: 'aee98743',
          prop: 'pickupDate',
          text: 'Pickup Date',
          computedText: (item, row) =>
            DateTime.fromISO(item, {
              zone: row.firstPickupTimeZone,
            }).toFormat('MM/dd/yyyy t ZZZZ'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          forceOneLine: true,
        },
        {
          _t_id: 'd383a520-fb6e-4ff8-bea6-de4461c0284c',
          prop: 'firstPickupAddressName',
          text: 'First Pickup Address',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          sortProp: 'trip.firstStopAddress.addressName',
          filterProp: 'trip.firstStopAddress.addressName',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: 'd1b0b105-d25b-473a-bd67-7e5b735571d0',
          prop: 'firstDropoffAddressName',
          text: 'First Dropoff Address',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          sortProp: 'trip.firstDropoffAddress.addressName',
          filterProp: 'trip.firstDropoffAddress.addressName',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: 'cc04e82a',
          prop: 'customerName',
          text: 'Customer',
          sort: true,
          filter: true,
          component: ReservationActionableColumn,
          type: 'text',
          action: 'CUSTOMER_DETAIL',
          detail: false,
          displayType: 'action-item',
          filterType: 'contains',
          predefined: deepClone(customerPredefined),
        }
      )

      if (!this.canViewOpsTotal) {
        columnsOut.push({
          _t_id: 'f72a351c',
          prop: 'amount',
          text: 'Customer Total',
          computedText: this.customerTotalComputedText,
          sort: true,
          filter: true,
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          filterType: 'eq',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        })
      }

      columnsOut.push(
        {
          _t_id: '1499873d-81c5-45d4-a7e7-42b1d9965086',
          prop: 'clientTotalAdjusted',
          text: 'Total',
          computedText: (item) => currencyFilter(item),
          sort: true,
          filter: true,
          type: 'number',
          filterType: 'eq',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
          defaultHidden: true,
        },
        {
          _t_id: '27fb8cf4',
          prop: 'contractId',
          text: 'Contract ID',
          sort: true,
          filter: true,
          type: 'text',
          method: 'or',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
        },
        {
          _t_id: 'ac5ffd38-0aab-490a-ba73-47883773ad18',
          prop: 'customerAccountName',
          text: 'Customer Account',
          sort: true,
          filter: true,
          type: 'text',
          detail: false,
          filterType: 'contains',
          childMethod: 'and',
          defaultHidden: true,
        },
        {
          _t_id: '063dca2e-545e-4e54-9c38-eac222485bf1',
          prop: 'productClassificationLabel',
          text: 'Product',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          method: 'and',
          childMethod: 'and',
          filterType: 'contains',
          predefined: textLike,
          defaultHidden: true,
        },
        {
          _t_id: '0385bce7-7d03-4c84-b883-f846fb7bbbef',
          prop: 'sourcingTeamClassificationLabel',
          text: 'Sourcing Team',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          method: 'and',
          childMethod: 'and',
          filterType: 'contains',
          predefined: textLike,
          defaultHidden: true,
        },
        {
          _t_id: 'e6065bda-0ace-404d-8a1f-90c6e088bbc4',
          prop: 'supportTeamClassificationLabel',
          text: 'Support Team',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          method: 'and',
          childMethod: 'and',
          filterType: 'contains',
          predefined: textLike,
          defaultHidden: true,
        },
        {
          _t_id: '8fc6ec7f-2139-481d-8a9c-24a296a15d93',
          prop: 'incompleteItinerary',
          text: 'Incomplete Itinerary',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: true,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '843219vd',
          prop: 'referralAcceptanceExpiration',
          text: 'Referral Acceptance Expiration',
          computedText: (item) =>
            item
              ? DateTime.fromISO(item, {
                  zone:
                    this.currentUser?.timeZone ||
                    this.currentUser?.company?.address?.timeZone,
                }).toFormat('MM/dd/yyyy t ZZZZ')
              : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(noFutureDatesPredefined),
          defaultSort: true,
        },
        {
          _t_id: '444e9854-c8d6-48f1-a28b-ebe4827808c0',
          prop: 'referralHasRequestedCancellation',
          text: 'Operator Cancel Request',
          computedText: (item) => (item ? 'Yes' : 'No'),
          sort: false,
          filter: true,
          detail: false,
          defaultHidden: true,
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          filterType: 'eq',
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '436c54d5',
          prop: 'assignedDriverPercentage',
          text: 'Driver',
          computedText: this.assignedDriverComputedText,
          sort: false,
          filter: true,
          detail: false,
          action: 'ASSIGNED_DRIVER_VEHICLE_DETAIL_BROKER',
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          filterType: 'eq',
          predefined: deepClone(assignmentPercentagePredefined),
        },
        {
          _t_id: '8de7075a',
          prop: 'assignedVehiclePercentage',
          text: 'Vehicle',
          computedText: this.assignedVehicleComputedText,
          sort: false,
          filter: true,
          detail: false,
          action: 'ASSIGNED_DRIVER_VEHICLE_DETAIL_BROKER',
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          filterType: 'eq',
          predefined: deepClone(assignmentPercentagePredefined),
        },
        {
          _t_id: 'd025d361',
          prop: 'bookedByName',
          text: 'Booked By',
          component: ReservationActionableColumn,
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          action: 'BOOKED_BY_DETAIL',
          displayType: 'action-item',
          filterType: 'contains',
          predefined: userPredefined,
        },
        {
          _t_id: '4464729b',
          prop: 'createdOn',
          text: 'Created Date',
          computedText: (item) =>
            item
              ? DateTime.fromISO(item, {
                  zone:
                    this.currentUser?.timeZone ||
                    this.currentUser?.company?.address?.timeZone,
                }).toFormat('MM/dd/yyyy t ZZZZ')
              : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: false,
          predefined: deepClone(noFutureDatesPredefined),
          defaultSort: true,
        },
        {
          _t_id: '37a7630b',
          prop: 'openForBid',
          text: 'Open For Bid',
          sort: true,
          filter: true,
          detail: false,
          defaultHidden: true,
          filterType: 'eq',
          computedText: (item) => {
            return item ? 'Open' : 'Closed'
          },
          customFilterTabDisplay: (val) => {
            return val ? 'Open' : 'Closed'
          },
          predefined: [
            {
              text: 'Open',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'Closed',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: 'e6023fe3',
          prop: 'spab',
          text: 'SPAB',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'eq',
          computedText: (item) => {
            return item ? 'Yes' : 'No'
          },
          customFilterTabDisplay: (val) => {
            return val ? 'Yes' : 'No'
          },
          defaultHidden: true,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '58ce0f3e',
          prop: 'mountain',
          text: 'Mountain',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'eq',
          computedText: (item) => {
            return item ? 'Yes' : 'No'
          },
          customFilterTabDisplay: (val) => {
            return val ? 'Yes' : 'No'
          },
          defaultHidden: true,

          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '92b229dd',
          prop: 'ada',
          text: 'ADA',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'eq',
          computedText: (item) => {
            return item ? 'Yes' : 'No'
          },
          customFilterTabDisplay: (val) => {
            return val ? 'Yes' : 'No'
          },
          defaultHidden: true,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: 'efa99657-bcc5-4a94-9bd2-f87123891334',
          prop: 'driverInfoEmailSent',
          text: 'Driver Info Email Sent',
          sort: true,
          filter: true,
          computedText: (item) => (item ? 'Sent' : 'Not Sent'),
          filterType: 'eq',
          customFilterTabDisplay: (item) => (item ? 'Sent' : 'Not Sent'),
          defaultHidden: true,
          predefined: [
            {
              text: 'Sent',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'Not Sent',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: 'xb7bcc554',
          prop: 'charterUpQuote',
          text: 'Marketplace',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: true,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '9f7294b0-edce-487c-8cf1-cdee2587d3a5',
          prop: 'isPendingConfirmation',
          text: 'Pending Confirmation',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: true,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: 'a4610ecd',
          prop: 'isPreBooking',
          text: 'Pre-Booking',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: true,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '00f13adc13f911ed861d0242ac120002',
          prop: 'allocatedToManagedId',
          text: 'Allocated To Managed ID',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          sortProp: 'allocatedToManagedId',
          filterProp: 'allocatedToManagedId',
          filterType: 'contains',
          childMethod: 'and',
          defaultHidden: true,
        },
        {
          _t_id: 'a001e6cf-05d2-4079-9ff9-a0e4eb1969da',
          prop: 'allocatedFromManagedId',
          text: 'Allocated',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          sortProp: 'allocatedFromManagedId',
          filterProp: 'allocatedFromManagedId',
          childMethod: 'and',
          defaultHidden: true,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              childMethod: 'and',
              withValue: true,
              value: 0,
              filterType: 'isnotnull',
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              childMethod: 'and',
              withValue: true,
              value: 1,
              filterType: 'isnull',
            },
            {
              text: 'Search',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              childMethod: 'and',
              controls: [
                {
                  text: 'Search',
                  filterType: 'contains',
                  splitByProp: true,
                },
              ],
            },
          ],
        },
        {
          _t_id: 'a637fc02',
          prop: 'needsManualReferral',
          text: 'MARGE Enabled',
          computedText: (item) => (item ? 'No' : 'Yes'),
          customFilterTabDisplay: (item) => (item ? 'No' : 'Yes'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: true,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
          ],
        },
        {
          _t_id: '243fc468',
          prop: '/',
          text: 'Referred By',
          component: ReservationActionableColumn,
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          list: 'referredBy',
          displayType: 'referred-by',
          sortProp: 'referredBy',
          filterProp: 'referredBy',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(textLike),
          defaultHidden: true,
        },
        {
          _t_id: '1791630b',
          prop: '/',
          text: 'Referred To',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          component: ReservationActionableColumn,
          list: 'referredTo',
          listProp: 'companyName',
          displayType: 'referred-to',
          action: 'REFERRED_TO_DETAIL',
          sortProp: 'referredTo',
          filterProp: 'referredTo',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(textLike),
          permissions: ['canViewReferredTo'],
        },
        {
          _t_id: 'a9818d8e',
          prop: 'quoteId',
          component: QuotesActionableColumn,
          displayType: 'clickable-link-res-quote-id',
          text: 'Quote ID',
          sort: true,
          filter: true,
          detail: false,
          type: 'number',
          filterType: 'eq',
          defaultHidden: true,
        },
        {
          _t_id: 'a9818d8d',
          prop: 'dueDate',
          text: 'Due Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          defaultHidden: true,
        },
        {
          _t_id: 'e53ec354',
          prop: 'tripTypeLabel',
          text: 'Trip Type',
          sort: true,
          filter: true,
          type: 'text',
          method: 'and',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: '8f1eb217',
          prop: 'routeName',
          text: 'Trip Name',
          sort: true,
          filter: true,
          type: 'text',
          method: 'and',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: '1cd7a99f',
          prop: 'referredAmount',
          text: 'Referral Total',
          computedText: this.referralTotalComputedText,
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'contains',
          predefined: deepClone(numericRangePredefined),
          permissions: ['canViewReferralTotal'],
        },
        {
          _t_id: '63578070',
          prop: 'referralStatus',
          text: 'Referral Status',
          computedText: (item) => this.mapCategoryText(referralStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: '5a4b6e86',
          prop: 'reservationStatus',
          text: 'Reservation Status',
          computedText: (item) =>
            this.mapCategoryText(reservationStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: 'ab65abb5',
          prop: 'paymentStatus',
          text: 'Payment Status',
          computedText: (item) => this.mapCategoryText(paymentStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: 'c8da5438',
          prop: 'referralPaymentStatus',
          text: 'Referral Payment Status',
          computedText: (item) => this.mapCategoryText(paymentStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
          permissions: ['canViewReferralPaymentStatus'],
        },
        {
          _t_id: '32920d6e',
          prop: 'pickupLocation',
          text: 'Pickup Location',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
          childMethod: 'or',
          unset: ['6b01a5c0', 'c22dbed8'],
          predefined: [
            {
              text: 'Radius',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '9522531a',
                  text: 'Location Radius',
                  filterType: 'radius',
                  component: PickupRadiusFilter,
                },
              ],
            },
            {
              text: 'Multiple Markets',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '37e10ab1',
                  text: 'Multiple Markets',
                  filterType: 'contains',
                  component: MultiMarketFilterVue,
                },
              ],
            },
            {
              text: 'Search',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  text: 'Search',
                  filterType: 'contains',
                },
              ],
            },
          ],
        },
        {
          _t_id: '17d71ae2',
          prop: 'balance',
          text: 'Balance',
          computedText: (item) => currencyFilter(item),
          component: ReservationActionableColumn,
          displayType: 'action-item',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'gt',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: '3b627c5d',
          prop: 'distance',
          text: 'Distance',
          computedText: (item) => metersToMilesString(item),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: '52a296fe',
          prop: 'drivingTime',
          text: 'Driving Time',
          computedText: (item) => {
            return `${Duration.fromMillis(item * 1000).toFormat(
              "h 'Hrs', m 'Min'"
            )}`
          },
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: 'e9bbe02f',
          prop: 'firstSentDate',
          text: 'Referred Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(noFutureDatesPredefined),
          defaultHidden: true,
        },
        {
          _t_id: 'adcdd544',
          prop: 'dropoffDate',
          text: 'First Dropoff Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '9559fb67-a8c0-4830-89e2-9308c6f5592c',
          prop: 'finalDropoffDate',
          text: 'Final Dropoff Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '11cd6d05',
          prop: 'cancelledOn',
          text: 'Cancelled Date',
          computedText: (item) =>
            item ? DateTime.fromISO(item).toFormat('MM/dd/yyyy') : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(noFutureDatesPredefined),
        },
        {
          _t_id: '6b01a5c0',
          prop: 'distanceFromMarket',
          filterType: '',
          filterPlaceholder: true,
        },
        {
          _t_id: 'c22dbed8',
          prop: 'marketId',
          filterType: 'eq',
          filterPlaceholder: true,
        },
        {
          _t_id: '1eb6452a',
          prop: 'nearestMarketId',
          filterType: 'eq',
          filterPlaceholder: true,
        },
        {
          _t_id: '019adaf4-9cde-4639-bb34-aac9af83feb9',
          prop: 'requiredDrivers',
          text: 'Total Drivers',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'eq',
        },
        {
          _t_id: '80f6d8f0-88c3-4630-b742-d66ed40859ec',
          prop: 'requiredVehicles',
          text: 'Total Vehicles',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'eq',
        },
        {
          _t_id: '87a2feb7-b5b5-4db8-a29a-78dfa1c1a8e1',
          prop: 'trackingDataString',
          text: 'Tracked Status',
          sort: false,
          filter: false,
          defaultHidden: true,
          type: 'text',
          component: TrackingColumn,
        },
        {
          _t_id: 'd7621ad4-8b27-4a06-98a8-f014beeb30f8',
          prop: 'referralOperatorTierLabels',
          text: 'Operator',
          computedText: (item) => {
            const labelList = item.referralOperatorTierLabels
            if (labelList === null) {
              return null
            }
            return labelList.split('|').join(', \n')
          },
          sort: false,
          filter: true,
          detail: false,
          filterType: 'contains',
          defaultHidden: false,
          component: ReservationActionableColumn,
          displayType: 'pre-line',
          predefined: [
            {
              text: 'Operator Tier Labels',
              controlType: 'default-repeat',
              defaultOnSelection: true,
              refreshOnSelection: true,
              controls: [
                {
                  id: 'd8187179-acbe-4fad-a1df-49b595af63ac',
                  text: 'Operator Tier Labels',
                  filterType: 'contains',
                  component: OperatorTierTypeLabelsFilter
                }
              ]
            }
          ],
        },
      )

      if (this.isTiersEnabled) {
        columnsOut.push(
          {
            _t_id: '69772b17-cef8-402c-b29a-bcc537225441',
            prop: 'tierId',
            text: 'Tier',
            computedText: (item) => this.mapCategoryText(this.tiersTVMap, item),
            sort: true,
            filter: true,
            detail: false,
            filterType: 'eq',
            defaultHidden: false,
            predefined: this.predefinedTiersTVMap,
          }
        )
      }

      columnsOut.push(
        {
          _t_id: 'fb43fe6a-88b1-11ee-b9d1-0242ac120002',
          prop: 'trackedStatus',
          text: 'Tracked Status',
          computedText: (item) => this.mapCategoryText(trackedStatusMap, item),
          sort: true,
          filter: false,
          type: 'text',
          filterType: 'eq',
          defaultHidden: false,
        }
      )

      if (this.tableProps.canViewInactiveReservations) {
        columnsOut.push({
          _t_id: 'bdea72a0-4b39-419b-814b-5afd038f137e',
          prop: 'active',
          text: 'Active',
          computedText: (item) => (item ? 'Yes' : 'No'),
          sort: false,
          filter: true,
          detail: false,
          defaultHidden: true,
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          filterType: 'eq',
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        })
      }
      if (this.canViewGrossProfit) {
        columnsOut.push({
          _t_id: '21e3164b-4c05-4d71-ae2d-efc1f101fa84',
          prop: 'grossProfit',
          text: 'Gross Profit',
          computedText: (item) => currencyFilter(item),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'gt',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        })
      }
      columnsOut.push(
        {
          _t_id: '25316bf6-f946-41ef-a889-d0d61d04e1af',
          prop: 'customerEmail',
          text: 'Customer Email',
          sort: true,
          filter: true,
          type: 'text',
          detail: false,
          filterType: 'contains',
          childMethod: 'and',
          defaultHidden: true,
          predefined: deepClone(textLike),
        },
        {
          _t_id: '732f4ad0-f78d-4b90-81dc-eea8924fa303',
          prop: 'targetReferralAmount',
          text: 'Target Referral',
          computedText: (item) => currencyFilter(item),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'gt',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: 'f21b63c9-3ff0-423a-b2bd-05bb69fb835b',
          prop: 'maxReferralAmount',
          text: 'Max Referral',
          computedText: (item) => currencyFilter(item),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'gt',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: '85fa1e9e-0391-49c6-a67c-ee7bfccabc77',
          prop: 'paymentMethodTypes',
          text: 'Payment Methods',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: 'b1818638-ca50-4836-a5fa-0d91bc27d038',
          prop: 'isEnterprise',
          text: 'Enterprise',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: true,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        }
      )

      if (this.isAccountExecutiveAndOpportunityIdEnabled) {
        columnsOut.push(
          {
            _t_id: '2d96c8a4-8248-11ee-b962-0242ac120002',
            prop: 'accountExecutiveName',
            text: 'Account Executive',
            sort: true,
            filter: true,
            filterType: 'contains',
            defaultHidden: true,
            type: 'text',
            childMethod: 'and',
          },
          {
            _t_id: '881cd20a-8248-11ee-b962-0242ac120002',
            prop: 'opportunityId',
            text: 'Opportunity ID',
            sort: true,
            filter: true,
            filterType: 'contains',
            defaultHidden: true,
            type: 'text',
            childMethod: 'and',
          }
        )
      }


      if (this.isAccountsReceivable) {
        columnsOut.push({
          _t_id: '83d0576f-a8c6-40ce-bf10-b5265b2d7f2f',
          prop: 'poNumber',
          text: 'PO Number',
          type: 'text',
          sort: false,
          filter: true,
          filterType: 'contains',
        })

        columnsOut.push({
          _t_id: 'd6805a3d-877f-4ba0-9fcd-2fda523e78ea',
          prop: 'poStatus',
          text: 'PO Status',
          computedText: (item) => this.poStatusComputedText(item),
          sort: false,
          filter: false,
        })
      }

      this.tableProps.columns = columnsOut
    },
    async getTable() {
      const requestId = v4()
      this.latestRequestId = requestId
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const companyId = this.currentUser?.companyId
      const params = {
        sorts,
        filters,
        companyId,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        reservations: 'company',
      }
      this.tableProps.selectedRows = []
      this.tableProps.loading = true
      const reservationData = await this.$store.dispatch(
        'reservations/reservationsTV',
        params
      )

      if (this.latestRequestId !== requestId) {
        return
      }

      const reservations = reservationData.data
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = reservations.resultList
      this.tableProps.total = reservations.count
      this.tableProps.loading = false
      if (reservationData) {
        this.tableProps.list.forEach((item) => {
          item.deleteMe = this.handleDelete.bind(this)
        })
      }
      EventBus.$emit('reservations-refreshed')
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    poStatusComputedText(item) {
      if (!item) {
        return ''
      }

      return capitalize(item)
    },
    mapCategoryText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },
    customerTotalComputedText(row, column, action) {
      if (!row.amount) {
        return
      }
      const {
        paymentStatus,
        amount,
        balance,
        depositAmount,
        dueDate,
        usedAuthHoldOnCheckout,
        createdOn,
        referralStatus,
        cancellationStatusKey,
        billAfterServices,
        poStatus,
        poNumber
      } = row
      const isPaymentOverdue = dueDate && DateTime.local() > DateTime.fromISO(dueDate)
      const isUnpaidWithHold = this.isUnpaidWithAuthHold({
        referralStatus,
        billAfterServices,
        usedAuthHoldOnCheckout,
        cancellationStatusKey,
        createdOn,
        amount,
        balance
      })

      const paymentStatusKey = this.getPaymentStatusKey({
        isUnpaidWithHold,
        paymentStatus,
        billAfterServices
      })

      function createTooltipHTML() {
        const unpaidHeader = `Unpaid${isPaymentOverdue ? ' (Overdue)' : ''}`
        const unpaidWithHoldHeader = `${unpaidHeader}${isUnpaidWithHold ? ' (With Hold)' : ''}`

        const paymentStatusHeaderMap = {
          not_paid: unpaidHeader,
          not_paid_with_hold: unpaidWithHoldHeader,
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
          bill_after_services: 'Bill After Services'
        }

        const totalOwed = `Total Owed: ${currencyFilter(amount)}`
        const balanceDue = paymentStatus !== 'fully_paid' ? `<br />Balance Due: ${currencyFilter(balance)}` : ''
        const ccHold = isUnpaidWithHold ? `<br />CC Hold: ${currencyFilter(depositAmount)}` : ''

        const secondLine = !billAfterServices ? `<p>${totalOwed}${balanceDue}${ccHold}</p>` : `<h6>(PO ${capitalize(poStatus)})</h6>`
        const thirdLine = !billAfterServices || !poNumber ? '' : `<p>PO #${poNumber}</p>`

        return `<h6>${paymentStatusHeaderMap[paymentStatusKey]}</h6>${secondLine}${thirdLine}`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          not_paid_with_hold: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
          bill_after_services: 'unpaid'
        }
        return iconMap[paymentStatusKey]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          not_paid_with_hold: 'warning',
          partially_paid: 'warning',
          fully_paid: 'success',
          bill_after_services: 'warning'
        }
        return colorMap[paymentStatusKey]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, amount),
      }

      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    isUnpaidWithAuthHold({
      referralStatus,
      billAfterServices,
      usedAuthHoldOnCheckout,
      cancellationStatusKey,
      createdOn,
      amount,
      balance
    }) {
      const isReservationUnpaid = ![
        ReferralStatus.Accepted, ReferralStatus.FullyAccepted, ReferralStatus.Confirmed, ReferralStatus.FullyConfirmed
      ].includes(referralStatus)

      const isRecentAuthHold = diffInDaysFromNow(createdOn) > -30
      const isBalanceUnpaid = amount === balance

      return !billAfterServices &&
            usedAuthHoldOnCheckout &&
            cancellationStatusKey !== 'cancelled' &&
            isReservationUnpaid &&
            isRecentAuthHold &&
            isBalanceUnpaid
    },
    getPaymentStatusKey({ isUnpaidWithHold, paymentStatus, billAfterServices }) {
      if (isUnpaidWithHold) return 'not_paid_with_hold'
      if (billAfterServices) return 'bill_after_services'
      return paymentStatus || 'not_paid'
    },
    referralTotalComputedText(row, column, action) {
      const { referralPaymentStatus, referralBalance, referredAmount } = row
      const numericReferralBalance =
        typeof referralBalance === 'number' ? referralBalance : 0
      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: 'Unpaid',
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${
          paymentStatusHeaderMap[referralPaymentStatus || 'not_paid']
        }</h6>
          <p>Total Owed: ${currencyFilter(referredAmount)}${
          referralPaymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(numericReferralBalance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
          bill_after_services: 'unpaid'
        }
        return iconMap[referralPaymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[referralPaymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, referredAmount),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    assignedDriverComputedText(row, column, action) {
      const { assignedDriverPercentage } = row

      function createTooltipHTML() {
        const tooltipText =
          assignedDriverPercentage >= 100
            ? 'Fully Assigned'
            : 'Needs Assignment'
        return `<h6>${tooltipText}</h6>`
      }

      function getIconValue() {
        return 'drivers'
      }

      function getIconColor() {
        return assignedDriverPercentage >= 100 ? 'success' : 'error'
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        noop: () => '',
      }
      const actionFunction = actionMap[action || 'noop']
      return actionFunction()
    },
    assignedVehicleComputedText(row, column, action) {
      const { assignedVehiclePercentage } = row

      function createTooltipHTML() {
        const tooltipText =
          assignedVehiclePercentage >= 100
            ? 'Fully Assigned'
            : 'Needs Assignment'
        return `<h6>${tooltipText}</h6>`
      }

      function getIconValue() {
        return 'vehicles'
      }

      function getIconColor() {
        return assignedVehiclePercentage >= 100 ? 'success' : 'error'
      }

      const actionMap = {
        tooltip: createTooltipHTML,
        icon: getIconValue,
        iconcolor: getIconColor,
        noop: () => '',
      }
      const actionFunction = actionMap[action || 'noop']
      return actionFunction()
    },
    rowClass(rowProps) {
      const { item } = rowProps
      if (item.isPreBooking) {
        return 'purple-background'
      }
      if (
        item.balance > 0 &&
        DateTime.local() > DateTime.fromISO(item.dueDate)
      ) {
        return 'error-background'
      }
      if (
        ['upcoming', 'hold'].includes(item.reservationStatus) &&
        ['not_offered', 'offered', 'fully_offered'].includes(
          item.referralStatus
        ) &&
        DateTime.fromISO(item.pickupDate) > DateTime.local() &&
        !item.needsManualReferral
      ) {
        return 'yellow-background'
      }
    },
    canExport() {
      const roles = this.currentUserProfile?.roles || []
      const canExportRole = roles.find(
        (r) => r.roleName === 'can_export_reservations'
      )
      return !!canExportRole
    },
    handleDelete(targetRow) {
      this.tableProps.list = this.tableProps.list.filter(
        (row) => row.reservationId !== targetRow.reservationId
      )
    },
    unselectRow(item) {
      const reservationId = item.reservationId
      const index = this.tableProps.selectedRows.find(
        (r) => r.item?.reservationId === reservationId
      ).index
      this.tableProps.selectedRows.splice(index, 1)
    },
  },
}
</script>
